import styled, { css } from 'styled-components';

const arrowSize = css`get-vw(21px)`;
const arrowSizeMax = '24px';

const SliderArrow = styled.button`
  position: absolute;
  top: 50%;
  width: ${arrowSize};
  height: ${arrowSize};
  margin-top: -(${arrowSize} / 2);
  z-index: ${({ $zIndex }) => $zIndex || 11};
  outline: none;
  border: 0;
  border-radius: 50%;
  background-size: cover;
  background-color: transparent;

  @media only screen and (min-width: 480px) {
    width: ${arrowSizeMax};
    height: ${arrowSizeMax};
    margin-top: -(${arrowSizeMax} / 2);
  }
`;

export const ArrowNext = styled(SliderArrow)`
  background-image: url('/static/images/item-slider-arrow-right.svg');
  right: get-vw(10px);
  left: auto;

  @media only screen and (min-width: 480px) {
    right: 10px;
  }
`;

export const ArrowPrev = styled(SliderArrow)`
  background-image: url('/static/images/item-slider-arrow-left.svg');
  left: get-vw(10px);
  right: auto;

  @media only screen and (min-width: 480px) {
    left: 10px;
  }
`;
