import React, { useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowNext, ArrowPrev } from '../ui/Swiper/styled';

const Swiper = React.forwardRef(({ images, renderItem }, swiperRef) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(null);

  function slideNext() {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
  }

  function slidePrev() {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(prevIndex);
  }

  function handleTouchStart(e) {
    setStartX(e.touches[0].clientX);
  }

  function handleTouchEnd(e) {
    if (!startX) return;

    const endX = e.changedTouches[0].clientX;
    const diffX = startX - endX;
    const THRESHOLD = 30;

    if (diffX > THRESHOLD) slideNext();
    if (diffX < -THRESHOLD) slidePrev();

    setStartX(null);
  }

  useImperativeHandle(
    swiperRef,
    () => ({
      slideNext,
      slidePrev
    }),
    []
  );

  return (
    <div
      className="swiper-container"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className="swiper-track"
        style={{
          transform: `translate3d(-${currentIndex * 100}%, 0px, 0px)`
        }}
      >
        {images.map((image, index) => (
          <div className="swiper-slide" key={index}>
            {renderItem(image, index)}
          </div>
        ))}
      </div>
      <ArrowPrev onClick={slidePrev} />
      <ArrowNext onClick={slideNext} />
    </div>
  );
});

Swiper.propTypes = {
  images: PropTypes.array,
  renderItem: PropTypes.func
};

Swiper.displayName = 'Swiper';

export default Swiper;
