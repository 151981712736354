import styled, { css } from 'styled-components';
import Link from 'next/link';
import { EllipsisText, FlexColumn } from '../../../styled/common';
import { Typography } from '../Typography';
import { Breakpoints } from '../../../styled/constants';

export const cardMarginLeft = 10;
const marginLeft = `${cardMarginLeft}px`;

export const ItemList = styled.div`
  margin-left: -${marginLeft};
`;

const itemCardMixin = (itemsPerRow, hideExcessItems = false) => css`
  flex: 1 1 calc(100% / ${itemsPerRow});
  max-width: calc((100% / ${itemsPerRow}) - ${marginLeft});
  box-sizing: border-box;

  ${hideExcessItems &&
  css`
    &:nth-of-type(n + ${itemsPerRow + 1}) {
      display: none;
    }
  `}
`;

const horizontItemWidthMap = {
  [Breakpoints.tablet]: '33%',
  [Breakpoints.smallDesktop]: `calc((100% / 3) - (${marginLeft} * 2 / 3))`,
  [Breakpoints.desktop]: `calc((100% / 4) - (${marginLeft} * 0.75))`
};

const getItemCardStylesWithHorizontalScroll = () => css`
  min-width: 48.7%;
  margin-left: 8px;
  margin-bottom: 0;

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    margin-left: 10px;
  }

  &:first-child {
    margin-left: 0 !important;
  }

  @media screen and (max-width: 315px) {
    min-width: 100%;
  }

  ${Object.entries(horizontItemWidthMap).map(
    ([breakpoint, width]) => css`
      @media screen and (min-width: ${breakpoint}px) {
        min-width: ${width};
      }
    `
  )}
`;

export const itemCardStyles = css`
  flex: 1 1 calc(100% / 6);
  max-width: calc(100% / 6 - ${marginLeft});
  position: relative;
  vertical-align: top;
  z-index: 1;
  overflow: hidden;
  margin: 0 0 15px ${marginLeft};
  text-decoration: none;

  ${({ $hideExcessItems, $hasHorizontalScroll, theme }) => css`
    @media screen and (min-width: ${theme.desktopBreakpoint}) {
      ${itemCardMixin(4, $hideExcessItems)};
    }
    @media screen and (min-width: ${theme.tabletBreakpoint}) and (max-width: ${theme.desktopBreakpoint}) {
      ${itemCardMixin(3, $hideExcessItems)};
    }
    @media screen and (min-width: 316px) and (max-width: ${theme.tabletBreakpoint}) {
      ${itemCardMixin(2, $hideExcessItems)};
    }
    @media screen and (max-width: 315px) {
      ${itemCardMixin(1, $hideExcessItems)};
    }

    ${$hasHorizontalScroll && getItemCardStylesWithHorizontalScroll()};
  `};
`;

export const ItemCardStyled = styled.div`
  ${itemCardStyles}
`;

const IconBox = styled.span`
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  transform: translateZ(0);
`;

const ServiceIconBox = styled(IconBox)`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 12px;
  background-size: 12px 10px;
`;

export const FeaturedIcon = styled(ServiceIconBox)`
  background-image: url('/static/images/featured-icon.svg');
`;

export const VippedIcon = styled(ServiceIconBox)`
  background-image: url('/static/images/vipped-icon.svg');
`;

export const CardLink = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  ${({ $withCarousel }) => $withCarousel && { zIndex: 3 }}
  bottom: ${({ $hasButton }) => ($hasButton ? '50px' : 0)}
`;

export const BillOfSaleIcon = styled(IconBox)`
  width: 16px;
  height: 16px;
  background-image: url('/static/images/new-icon-docs.svg');
`;

export const MortgageIcon = styled(IconBox)`
  width: 16px;
  height: 16px;
  background-image: url('/static/images/new-mortgage-icon.svg');
  margin-left: 1px;
`;

export const CardPreview = styled.div`
  position: relative;
  background-color: ${({ theme: { colors } }) => colors.background.secondary};
  background-image: url('/static/images/loading-spinner.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  z-index: 2;
  padding-top: 74.29%;

  img,
  & {
    border-radius: 14px;
  }
`;

export const Label = styled(Typography)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: ${({ theme: { colors } }) => colors.background.primary};
  text-align: center;
  line-height: 14px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 1;

  background-color: ${({ $itemType, theme: { colors } }) =>
    $itemType === 'agency' ? colors.systemColors.blue : '#af8329'};
`;

export const PriceContainer = styled(Typography)`
  display: flex;
  white-space: nowrap;
  align-items: center;
  height: 20px;

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    height: 22px;
  }
`;

export const Currency = styled.span`
  margin-left: 3px;
  ${({ $currency, theme }) =>
    $currency === 'AZN' &&
    css`
      background-image: url('/static/images/currency/manat-brown.svg');
      background-position: center;
      background-size: contain;
      font-size: 0;
      width: 11px;
      height: 11px;
      display: flex;
      background-repeat: no-repeat;
      background-size: 11px 11px;

      @media (min-width: ${theme.tabletBreakpoint}) {
        height: 13px;
      }
    `}
`;

export const CardParams = styled(FlexColumn)`
  box-sizing: border-box;
  overflow: hidden;
  z-index: -1;
  padding: 5px 0;

  & > span:not(:first-of-type) {
    margin-top: 3px;
  }
`;

export const CardParamsLocation = styled(Typography)`
  ${EllipsisText};
  margin-top: 5px;
`;

export const CardParamsName = styled(Typography)`
  display: flex;
  list-style: none;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;

  li {
    &:not(:last-child)::after {
      content: ', ';
      display: inline-block;
      margin-right: 1px;
    }

    &:last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const CardFooter = styled(Typography)`
  position: relative;
  width: 100%;
  line-height: 14px;
  box-sizing: border-box;
  margin-top: 5px !important;

  button {
    margin-top: 5px;
    height: 44px;
    @media (min-width: ${({ theme }) => theme.smallDesktopBreakpoint}) {
      height: 48px;
    }
  }

  div:nth-of-type(2) {
    padding: 0;
  }
`;
