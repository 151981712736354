import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Bookmark from '../../Bookmark';
import { getCardTime } from '../../../utils/helpers';
import ItemCarousel from '../../ItemCarousel';
import LazyImage from '../../HOC/lazyImage';
import { EllipsisText, FlexBetween, FlexibleDiv } from '../../../styled/common';
import {
  BillOfSaleIcon,
  CardFooter,
  CardLink,
  CardParams,
  CardParamsLocation,
  CardParamsName,
  CardPreview,
  FeaturedIcon,
  ItemCardStyled,
  Label,
  MortgageIcon,
  PriceContainer,
  VippedIcon
} from './styled';
import { getLocationName, getParamsList } from './heplers';
import { ArrowNext, ArrowPrev } from '../Swiper/styled';
import useScreenSize from '../../../hooks/useScreenSize';
import { BaseItemProps, SwiperDirection } from './types';
import { CurrencySymbol } from './CurrencySymbol';

export const ItemCard = <T extends BaseItemProps>(props: T) => {
  const { t, i18n } = useTranslation();

  const {
    stat,
    path,
    photos,
    photosCount,
    price,
    paidDaily,
    leased,
    vipped,
    city,
    hasBillOfSale,
    hasMortgage,
    featured,
    id,
    inBookmarks,
    removeItemHandle,
    carousel = false,
    itemId,
    setCarousel,
    updatedAt,
    cypress = 'item-card-link',
    company,
    isBusiness,
    hideExcessItems = false,
    showBookmark = true,
    hasHorizontalScroll = false,
    rooms,
    floor,
    area,
    floors,
    location,
    'cypress-card': card = 'item-card',
    button
  } = props;

  const locationName = getLocationName({ city, location });
  const { isSmall } = useScreenSize();
  const paramsList = getParamsList({ rooms, area, floor, floors }, t);
  const cardTime = getCardTime(updatedAt, i18n);
  const setCarouselId = () => setCarousel(id);

  const isCarouselLoaded = itemId === id;
  const [swiper, updateSwiper] = useState<SwiperDirection>(null);
  const [startX, updateStartX] = useState(null);
  const [startY, updateStartY] = useState(null);

  const thumbnail = photos.map((sub, subindex) => (
    <LazyImage key={subindex} src={sub.thumbnail} />
  ));

  const scrollOnStart = e => {
    const touchobj = e.changedTouches[0];
    updateStartX(touchobj.pageX);
    updateStartY(touchobj.pageY);
  };

  const scrollOnMove = e => {
    const { pageX, pageY } = e.changedTouches[0];
    const distX = pageX - startX;
    const distY = pageY - startY;
    if (Math.abs(distX) - Math.abs(distY) > 45) {
      setCarouselId();
      updateSwiper(distX < 0 ? SwiperDirection.Next : SwiperDirection.Prev);
    }
  };

  const itemType = company?.targetType?.toLowerCase() || '';

  const createTouchHandler = handler => e =>
    !carousel || isCarouselLoaded ? null : handler(e);

  const onTouchStartHandler = createTouchHandler(scrollOnStart);
  const onTouchMoveHandler = createTouchHandler(scrollOnMove);

  const onSwipHandler = (cursor = SwiperDirection.Next) => {
    setCarouselId();
    updateSwiper(cursor);
  };

  const carouselBtns = carousel && !isCarouselLoaded && (
    <>
      <ArrowNext
        $zIndex="0"
        onClick={onSwipHandler}
        onKeyPress={setCarouselId}
      />
      <ArrowPrev
        $zIndex="0"
        onClick={() => onSwipHandler(SwiperDirection.Prev)}
        onKeyPress={setCarouselId}
      />
    </>
  );

  const ifCarousel = isCarouselLoaded ? (
    <ItemCarousel
      {...{ photos, carousel, path, photosCount }}
      scrollTo={swiper}
    />
  ) : (
    <CardLink href={path} data-cy="card-link">
      {thumbnail[0]}
    </CardLink>
  );

  const cardPricePer = () => {
    if (!leased || paidDaily === null) return null;

    return (
      <span data-cy="item-card-price-container">
        {`/${t(`cards.item_card.${paidDaily ? 'per_day' : 'per_month'}`)}`}
      </span>
    );
  };

  return (
    <ItemCardStyled
      data-cy={card}
      $hideExcessItems={hideExcessItems}
      $hasHorizontalScroll={hasHorizontalScroll}
    >
      <CardLink
        $withCarousel={carousel}
        href={path}
        data-stat={stat}
        data-cy={cypress}
        $hasButton={!!button}
      />
      {showBookmark && (
        <Bookmark
          itemId={id}
          isActive={inBookmarks}
          removeItemFunc={removeItemHandle}
          stat="profile-card-bookmark"
        />
      )}
      <CardPreview
        onTouchStart={onTouchStartHandler}
        onTouchMove={onTouchMoveHandler}
        data-cy="item-card-preview"
      >
        {ifCarousel}
        {carouselBtns}
        {!!itemType.length && isBusiness && (
          <Label
            tag="caption_2"
            $itemType={itemType}
            data-cy={`product-label-${itemType}`}
          >
            {t(`cards.item_card.${itemType}`)}
          </Label>
        )}
      </CardPreview>
      <CardParams>
        <FlexBetween>
          <PriceContainer tag={isSmall ? 'h5_new' : 'h4_new'}>
            <span>{price.value.toLocaleString('ru-RU')}</span>
            <CurrencySymbol currency={price.currency} />
            {cardPricePer()}
          </PriceContainer>
          <FlexibleDiv>
            {hasBillOfSale && <BillOfSaleIcon />}
            {hasMortgage && <MortgageIcon />}
          </FlexibleDiv>
        </FlexBetween>
        <CardParamsLocation tag="body_1">{locationName}</CardParamsLocation>
        <CardParamsName tag={isSmall ? 'body_2' : 'body_1'}>
          {paramsList.map(([key, value]) => (
            <li key={key}>{value}</li>
          ))}
        </CardParamsName>
        <CardFooter color="secondary" tag="caption_1">
          <EllipsisText suppressHydrationWarning={true} data-cy="city_when">
            {city.name}, {cardTime}
          </EllipsisText>
          {featured && <FeaturedIcon />}
          {!featured && vipped && <VippedIcon />}
          {button}
        </CardFooter>
      </CardParams>
    </ItemCardStyled>
  );
};
