import { CompanyTargetTypeEnum } from '../../../graphql/graphql';

export enum SwiperDirection {
  Next = 'next',
  Prev = 'prev'
}

export interface Company {
  id: string;
  name: string;
  targetType: CompanyTargetTypeEnum;
}

export interface Location {
  fullName?: string;
  id: string;
  latitude?: number;
  locationGroup: object;
  locationGroupId: string;
  longitude?: number;
  name?: string;
  parent?: Location;
  path: string;
  slug: string;
}

export interface City {
  id: string;
  latitude?: number;
  locations?: Location[];
  longitude?: number;
  name?: string;
  path: string;
  slug: string;
}

export interface BaseItem {
  area: {
    units: string;
    value: number;
  };
  city: City;
  company?: Company;
  featured: boolean;
  floor?: number;
  floors?: number;
  hasBillOfSale?: boolean;
  hasMortgage?: boolean;
  hasRepair?: boolean;
  id: string;
  isBusiness: boolean;
  leased?: boolean;
  location?: Location;
  paidDaily?: boolean;
  path: string;
  photos: { [key: string]: string }[];
  photosCount: number;
  price: { currency: string; value: number };
  rooms?: number;
  updatedAt: string;
  vipped: boolean;
}

export interface BaseItemProps extends BaseItem {
  stat: string;
  itemId: string;
  inBookmarks: boolean;
  removeItemHandle: () => void;
  setCarousel: (value: string) => void;
  carousel?: boolean;
  cypress?: string;
  hideExcessItems?: boolean;
  hasHorizontalScroll?: boolean;
  showBookmark?: boolean;
  'cypress-card'?: string;
  button?: JSX.Element;
}

export interface GetLocationName {
  city: BaseItemProps['city'];
  location: BaseItemProps['location'];
}

export interface ParamsList {
  rooms: BaseItemProps['rooms'];
  area: BaseItemProps['area'];
  floor: BaseItemProps['floor'];
  floors: BaseItemProps['floors'];
}
